<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 v-if="garden.name" class="h2 text-white d-inline-block mb-0">
            {{ garden.name.it }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="" footer-classes="pb-2">
          <template v-slot:header>
            <h3 class="mb-0">
              Custom ticket codes
              <button @click="initNewGroup" class="btn btn-info btn=sm">
                Add codes
              </button>
            </h3>
            <p class="text-sm mb-0"></p>
          </template>

          <div class="row">
            <div class="col-md-12">
              <el-table
                :data="codeGroups"
                row-key="id"
                header-row-class-name="thead-light"
              >
                <el-table-column prop="ticket" label="Ticket Name" width="250">
                  <template #default="scope">
                    <span>{{ scope.row.ticket.name.it }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="total_codes"
                  label="Total codes"
                  width="150"
                >
                  <template #default="scope">
                    <span>{{ scope.row.total_codes }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="used_codes"
                  label="Available codes"
                  width="150"
                >
                  <template #default="scope">
                    <span>{{ scope.row.available_codes }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="active" label="Status" width="150">
                  <template #default="scope">
                    <span v-if="scope.row.active" class="badge badge-success"
                      >Active</span
                    >
                    <span v-if="!scope.row.active" class="badge badge-danger"
                      >Inactive</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="expire_date"
                  label="Expire date"
                  width="150"
                >
                  <template #default="scope">
                    <span>{{ scope.row.expire_date }}</span>
                  </template>
                </el-table-column>

                <el-table-column align="right" label="Actions">
                  <template #default="scope">
                    <div class="d-flex">
                      <button
                        type="button"
                        @click="initEditGroup(scope.row)"
                        class="btn base-button btn-icon btn-fab btn-primary btn-sm edit"
                      >
                        Edit
                      </button>
                      <base-switch
                        class="mr-1"
                        @update:modelValue="updateGroupStatus($event,scope.row)"
                        on-text="Active"
                        off-text="Inactive"
                        v-model="scope.row.active"
                      ></base-switch>
                      <button
                        type="button"
                        @click="initDeleteGarden(scope.row.id)"
                        class="btn base-button btn-icon btn-fab btn-danger btn-sm remove"
                      >
                        Delete
                      </button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
    <modal v-model:show="showTimeModal" modal-classes="modal-secondary">
      <div>
        <h4 style="border-bottom: 1px solid #ddd; padding-bottom: 5px">
          {{ eanGroupModel.id ? "Edit" : "Add" }} EAN codes
        </h4>

        <el-select
          class="select-primary select_ticket"
          v-model="eanGroupModel.ticket_id"
          placeholder="Ticket"
        >
          <el-option
            class="select-primary"
            v-for="ticket in garden.tickets"
            :key="ticket.id"
            :label="ticket.name.it"
            :value="ticket.id"
          >
          </el-option>
        </el-select>

        <span style="color: red" class="error" v-if="errors.ticket_id"
          >You need to select a ticket
        </span>

        <div style="margin-top: 15px">
          <vue-csv-import
            v-if="!eanGroupModel.id"
            v-model="eanGroupModel.codes"
            :fields="{ code: { required: false, label: 'EAN Code' } }"
          >
            <vue-csv-toggle-headers></vue-csv-toggle-headers>
            <vue-csv-errors></vue-csv-errors>
            <vue-csv-input class="select-file-wrapper"></vue-csv-input>
            <vue-csv-map
              class="table table-bordered table-csv-match"
            ></vue-csv-map>
          </vue-csv-import>
          <span style="color: red" class="error" v-if="errors.codes"
            >You need to add a CSV with EAN codes
          </span>
          <div class="codes-wrapper">
            <table class="table table-bordered">
              <tr>
                <th>EAN Code</th>
                <th>Status</th>
              </tr>
              <tr v-for="code in eanGroupModel.codes" :key="code.code">
                <td>{{ code.code }}</td>
                <td>
                  <span v-if="!eanGroupModel.id">
                    <span class="badge badge-success">Available</span>
                  </span>
                  <span v-else>
                    <span v-if="code.active" class="badge badge-success"
                      >Available</span
                    >
                    <span v-if="!code.active" class="badge badge-danger"
                      >Used</span
                    >
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="form-group" style="margin-top: 10px">
          <label>Expiration Date</label>
          <base-input name="date2" class="filter-date-range filter-element">
            <template v-slot="{ focus, blur }">
              <flat-picker
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true }"
                class="form-control datepicker"
                v-model="eanGroupModel.expire_date"
              >
              </flat-picker>
            </template>
          </base-input>
          <span style="color: red" class="error" v-if="errors.expire_date">
            You need to an expiration date for the EAN codes
          </span>
        </div>
      </div>

      <template v-slot:footer>
        <base-button
          :disabled="addGroupLoading"
          :loading="addGroupLoading"
          type="primary"
          @click="eanGroupModel.id ? updateGroup() : addGroup()"
        >
          {{ eanGroupModel.id ? "Update" : "Add" }} Group</base-button
        >
        <base-button
          type="link"
          class="ml-auto"
          @click="
            showTimeModal = false;
            currentSlotSelected = [];
          "
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { getGarden, updateGardenEanCodesGroups,deleteGardenEanCodesGroups } from "@/api/gardens";
import {
  getGardenEanCodesGroups,
  addGardenEanCodesGroups,
} from "@/api/gardens";
import Modal from "@/components/Modal";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  ElInput,
  ElOption,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";
import swal from "sweetalert2";

export default {
  name: "CustomTicketCodes",
  components: {
    Modal,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
  },
  inject: ["runToast"],

  data() {
    return {
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [3, 5, 10, 25, 50],
        total: 0,
      },
      showTimeModal: false,
      addGroupLoading: false,
      loading: false,
      garden: {},
      currentSlotSelected: [],
      eanGroupModel: {
        ticket_id: "",
        garden_id: "",
        expire_date: "",
        active: true,
      },
      codeGroups: [],
      errors: {
        ticket_id: false,
        expire_date: false,
        codes: false,
      },
    };
  },
  watch: {
    "pagination.currentPage"(val) {
      this.loadCodesGroups();
    },
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  created() {
    this.loadGarden();
  },
  methods: {
    validateCodeAdds() {
      this.errors.ticket_id = !this.eanGroupModel.ticket_id;
      this.errors.expire_date = !this.eanGroupModel.expire_date;
      this.errors.codes = this.eanGroupModel.codes.length === 0;
      return Object.values(this.errors).reduce((p, v) => v + p, 0);
    },
    initNewGroup() {
      this.eanGroupModel = {
        ticket_id: "",
        garden_id: this.$route.params.id,
        expire_date: "",
        active: true,
        codes: [],
      };

      this.showTimeModal = true;
    },
    updateCodesList(codes) {
      if (this.eanGroupModel.codes.length === 0) {
        this.eanGroupModel.codes = codes;
      } else {
        this.eanGroupModel.codes = [...codes, ...this.eanGroupModel.codes];
      }
    },
    initEditGroup(group) {
      this.eanGroupModel = {
        id: group.id,
        ticket_id: group.ticket.id,
        garden_id: this.$route.params.id,
        expire_date: group.expire_date,
        active: group.active,
        codes: group.total_codes_map,
      };

      this.showTimeModal = true;
    },
    addGroup() {
      if (this.validateCodeAdds() > 0) {
        return;
      }
      this.addGroupLoading = true;
      addGardenEanCodesGroups(this.eanGroupModel).then((res) => {
        this.showTimeModal = false;
        this.addGroupLoading = false;
        this.loadCodesGroups();
        this.runToast(
          "bottom-right",
          "success",
          "Group added"
        );
      });
    },
    updateGroup() {
      if (this.validateCodeAdds() > 0) {
        return;
      }
      this.addGroupLoading = true;
      updateGardenEanCodesGroups(this.eanGroupModel).then((res) => {
        this.showTimeModal = false;
        this.addGroupLoading = false;
        this.loadCodesGroups();
        this.runToast(
          "bottom-right",
          "success",
          "Group Updated"
        );
      });
    },
    updateGroupStatus(event,group){

      updateGardenEanCodesGroups({
        id: group.id,
        ticket_id: group.ticket.id,
        garden_id: this.$route.params.id,
        expire_date: group.expire_date,
        active: event,
        codes: group.total_codes_map,
      }).then((res) => {
        this.runToast(
          "bottom-right",
          "success",
          "Status Updated"
        );
      });
    },
    loadGarden() {
      getGarden(this.$route.params.id).then((res) => {
        this.garden = res;
        this.loadCodesGroups();
      });
    },
    loadCodesGroups() {
      const params = {
        garden_id: this.$route.params.id,
        ...this.pagination,
      };

      this.codeGroups = [];
      this.loading = true;

      getGardenEanCodesGroups(params).then((res) => {
        this.codeGroups = res.data;
        this.total = res.count;
        this.loading = false;
      });
    },
    async initDeleteGarden(id) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the EAN codes group",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });
      if (accept && id) {
        deleteGardenEanCodesGroups(id).then((res) => {
          this.runToast("bottom-right", "success", "The group was deleted");
          this.loadCodesGroups();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.time-options {
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 8px;
    display: flex;

    .time {
      border: 1px solid;
      display: block;
      border-radius: 12px;
      padding: 5px;
      margin-right: 15px;
      width: 130px;
      text-align: center;
      font-weight: bold;

      cursor: pointer;
    }

    .time:hover {
      background-color: #dddddd !important;
    }
  }

  .selected {
    background-color: #dd8c55 !important;
  }
}

.ticket-list {
  list-style: none;
  padding-left: 0;
  li {
    margin-top: 10px;
    justify-content: space-between;
    display: flex;
  }
}

.custom-toggle {
  width: 72px !important;
}

.custom-toggle {
  input:checked + .custom-toggle-slider:before {
    transform: translateX(48px) !important;
  }
}

.select_ticket {
  width: 100%;
}

.select-file-wrapper {
  margin-bottom: 15px;
  display: block;
}

.table-csv-match {
  margin-top: 20px;
  margin-bottom: 20px;

  select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
}

.codes-wrapper {
  background: white;
  max-height: 350px;
  overflow-y: auto;
}
</style>
