<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Orders management</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--          <base-button @click="handleInitUsers" size="sm" type="neutral"-->
          <!--            >New</base-button-->
          <!--          >-->
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Orders list</h3>
            <p class="text-sm mb-0">
              <!--              This is a client side example of paginated tables using element-ui-->
              <!--              tables.-->
            </p>
          </template>
          <div>
            <div class="col-12 d-flex filters-wrapper">
              <el-input
                type="search"
                class="mb-3 filter-element"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px"
                placeholder="Search orders"
                v-model="searchQuery"
                aria-controls="datatables"
                @change="loadOrders"
              >
              </el-input>

              <!--              <el-select-->
              <!--                class="select-primary pagination-select"-->
              <!--                v-model="pagination.perPage"-->
              <!--                placeholder="Per page"-->
              <!--                @change="loadOrders"-->
              <!--              >-->
              <!--                <el-option-->
              <!--                  class="select-primary"-->
              <!--                  v-for="item in pagination.perPageOptions"-->
              <!--                  :key="item"-->
              <!--                  :label="item"-->
              <!--                  :value="item"-->
              <!--                >-->
              <!--                </el-option>-->
              <!--              </el-select>-->

              <base-input name="date2" class="filter-date-range filter-element">
                <template v-slot="{ focus, blur }">
                  <flat-picker
                    @on-open="focus"
                    @on-close="blur"
                    :config="{
                      allowInput: true,
                      mode: 'range',
                      altInput: true,
                      altFormat: 'd-m-Y',
                    }"
                    class="form-control datepicker"
                    placeholder="Filter Date"
                    @change="loadOrders"
                    v-model="filters.dateRange"
                  >
                  </flat-picker>
                </template>
              </base-input>

              <el-input
                type="search"
                class="mb-3 filter-element"
                clearable
                style="width: 200px"
                placeholder="Filter Gardens"
                v-model="filters.partial_garden_name"
                aria-controls="datatables"
                maxlength="25"
                @change="loadOrders"
              >
              </el-input>

              <div class="filter-element">
                <a :href="buildCSVDownloadURL()" class="btn btn-info"
                  >Export CSV</a
                >
              </div>

              <div class="filter-element">
                <a :href="buildXLSXDownloadURL()" class="btn btn-info"
                  >Export XLSX</a
                >
              </div>
            </div>
            <el-table
              :data="orders"
              row-key="id"
              header-row-class-name="thead-light"
            >
              <el-table-column prop="id" label="id">
                <template #default="scope">
                  <span>{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="name">
                <template #default="scope">
                  <span
                    >{{ scope.row.client.first_name }}
                    {{ scope.row.client.last_name }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="email" label="email">
                <template #default="scope">
                  <span>{{ scope.row.client.email }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="phone">
                <template #default="scope">
                  <span>{{ scope.row.client.phone }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="nationality" label="nationality">
                <template #default="scope">
                  <span>{{ scope.row.client.nationality }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="garden" label="garden">
                <template #default="scope">
                  <span>{{ scope.row.garden.name.en }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="amount">
                <template #default="scope">
                  <span>{{ scope.row.amount }}€</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="subscribed_to_newsletter"
                label="newsletter"
              >
                <template #default="scope">
                  <span>{{
                    scope.row.client.subscribed_to_newsletter ? "Yes" : "No"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="date" label="order date">
                <template #default="scope">
                  <span>{{ formatDate(scope.row.date) }}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="booking_date"
                label="booking date"
                width="150px"
              >
                <template #default="scope">
                  <span
                    v-if="getOrderTime(scope.row.ordersItems)"
                    v-html="getOrderTime(scope.row.ordersItems)"
                  >
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="right" label="Actions" width="160px">
                <template v-slot:default="scope">
                  <div>
                    <router-link :to="'/orders/' + scope.row.id">
                      <base-button
                        style="width: 100%; margin-bottom: 10px"
                        class="edit"
                        type="primary"
                        size="sm"
                        icon
                      >
                        Details
                      </base-button>
                    </router-link>
                    <a
                      v-if="scope.row.uuid"
                      target="_blank"
                      :href="
                        apiUrl +
                        '/pdf/tickets/ticket-' +
                        scope.row.uuid +
                        '.pdf'
                      "
                    >
                      <base-button
                        style="width: 100%; margin-bottom: 10px"
                        class="edit"
                        type="info"
                        size="sm"
                        icon
                      >
                        Ticket PDF
                      </base-button>
                    </a>

                    <a
                      v-if="scope.row.invoice_pdf_url"
                      target="_blank"
                      :href="scope.row.invoice_pdf_url"
                    >
                      <base-button
                        style="width: 100%; margin-bottom: 10px"
                        class="edit"
                        type="warning"
                        size="sm"
                        icon
                      >
                        Invoice PDF
                      </base-button>
                    </a>

                    <base-button
                      @click="createInvoice(scope.row.id)"
                      v-if="
                        !scope.row.invoice_pdf_url &&
                        scope.row.payment_reference &&
                        scope.row.garden.invoicing &&
                        getUser &&
                        getUser.role === 'admin'
                      "
                      style="width: 100%; margin-bottom: 10px"
                      class="edit"
                      type="warning"
                      size="sm"
                      icon
                    >
                      Create Invoice
                    </base-button>
                    <base-button
                      @click="retryTicketCreation(scope.row.id)"
                      v-if="
                        !scope.row.uuid &&
                        scope.row.payment_reference &&
                        (scope.row.invoice_pdf_url ||
                          !scope.row.garden.invoicing) &&
                        getUser &&
                        getUser.role === 'admin'
                      "
                      style="width: 100%; margin-bottom: 10px"
                      class="edit"
                      type="warning"
                      size="sm"
                      icon
                    >
                      Create Ticket
                    </base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </template>
        </card>

        <loading-panel v-if="loading"></loading-panel>

        <modal v-model:show="editUserModal">
          <template v-slot:header>
            <h6 class="modal-title">
              {{ userModel.id ? "Edit user" : "Add new user" }}
            </h6>
          </template>

          <Form ref="userForm">
            <div class="form-group">
              <label>Name</label>
              <Field
                :rules="isRequired"
                name="name"
                class="form-control"
                v-model="userModel.name"
              />
              <ErrorMessage name="name" style="color: orangered" />
            </div>

            <div class="form-group">
              <label>Email</label>
              <Field
                :rules="isRequired"
                name="email"
                class="form-control"
                v-model="userModel.email"
              />
              <ErrorMessage name="email" style="color: orangered" />
            </div>
            <div class="form-group">
              <label>Role</label>
              <Field
                name="role"
                :rules="isRequired"
                class="form-control"
                v-model="userModel.role"
                as="select"
              >
                <option value="admin">Administrator</option>
                <option value="garden_manager">Garden Manager</option>
              </Field>
              <ErrorMessage name="role" style="color: orangered" />
            </div>
            <div class="form-group">
              <label>New password</label>
              <Field
                :rules="!userModel.id ? isRequired : true"
                name="password"
                class="form-control"
                v-model="userModel.password"
              />
              <ErrorMessage name="password" style="color: orangered" />
            </div>
          </Form>

          <template v-slot:footer>
            <base-button
              size="sm"
              @click="userModel.id ? updateUser() : createUser()"
              type="primary"
            >
              {{ userModel.id ? "Update user" : "Add new user" }}</base-button
            >
            <base-button
              type="link"
              class="ml-auto"
              @click="editUserModal = false"
              >Close</base-button
            >
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import BasePagination from "@/components/BasePagination";
import LoadingPanel from "@/components/LoadingPanel";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { getGardens } from "@/api/gardens";
import {
  ElInput,
  ElOption,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";
import { createUser, updateUser } from "@/api/users";
import { Field, Form, ErrorMessage } from "vee-validate";
import Modal from "@/components/Modal";
import swal from "sweetalert2";
import { deleteUser } from "@/api/users";
import { getOrders, generateInvoice, retryTicketCreation } from "@/api/orders";
import dayjs from "dayjs";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getToken } from "@/util/auth";
import { mapGetters } from "vuex";

export default {
  name: "Orders",
  components: {
    flatPicker,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    Field,
    Form,
    ErrorMessage,
    LoadingPanel,
  },
  inject: ["runToast"],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      editUserModal: false,
      orders: [],
      total: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [3, 5, 10, 25, 50],
        total: 0,
      },
      gardens: [],
      filters: {
        garden_id: "",
        dateRange: "",
      },
      searchQuery: "",
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "name",
          label: "Name",
          minWidth: 200,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 250,
        },
        {
          prop: "phone",
          label: "Phone",
          minWidth: 250,
        },
        {
          prop: "nationality",
          label: "Nationality",
          minWidth: 250,
        },
        {
          prop: "garden",
          label: "Garden",
        },
        {
          prop: "date",
          label: "Order Date",
        },
        {
          prop: "booking_date",
          label: "Booking Date",
        },
        {
          prop: "total",
          label: "Total",
        },
      ],
      userModel: {
        name: "",
        email: "",
        password: "",
        role: "",
      },
      loading: false,
    };
  },
  watch: {
    "pagination.currentPage"(val) {
      this.loadOrders();
    },
  },
  computed: {
    ...mapGetters([
      "getUser",

      // ...
    ]),
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  mounted() {
    this.loadOrders();
    getGardens({ perPage: 100 }).then((res) => {
      this.gardens = res.data;
    });
  },
  methods: {
    getOrderTime(obj) {
      const item = obj[Object.keys(obj)[0]];
      if (item) {
        return item.selectedDate + " <br> " + item.selectedTime;
      } else {
        return "";
      }
    },
    buildCSVDownloadURL() {
      return (
        process.env.VUE_APP_API_URL +
        `/orders/export?searchQuery=${encodeURIComponent(
          this.searchQuery
        )}&dateRange=${encodeURIComponent(
          this.filters.dateRange
        )}&partial_garden_name=${encodeURIComponent(
          this.filters.partial_garden_name ?? ""
        )}&garden_id=${encodeURIComponent(
          this.filters.garden_id
        )}&secret_token=${getToken()}`
      );
    },
    buildXLSXDownloadURL() {
      return (
        process.env.VUE_APP_API_URL +
        `/orders/exportxlsx?searchQuery=${encodeURIComponent(
          this.searchQuery
        )}&dateRange=${encodeURIComponent(
          this.filters.dateRange
        )}&partial_garden_name=${encodeURIComponent(
          this.filters.partial_garden_name ?? ""
        )}&garden_id=${encodeURIComponent(
          this.filters.garden_id
        )}&secret_token=${getToken()}`
      );
    },
    updateUser() {
      this.$refs.userForm.validate().then((res) => {
        if (res.valid) {
          updateUser(this.userModel.id, this.userModel).then((res) => {
            this.runToast("bottom-right", "success", "The user was updated");
            this.editUserModal = false;
            this.loadOrders();
          });
        }
      });
    },
    createUser() {
      this.$refs.userForm.validate().then((res) => {
        if (res.valid) {
          createUser(this.userModel).then((res) => {
            this.runToast("bottom-right", "success", "The user was created");
            this.editUserModal = false;
            this.loadOrders();
          });
        }
      });
    },
    handleSelect() {},
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }

      return "This is required";
    },

    handleEdit(user) {
      this.userModel = user;
      this.editUserModal = true;
    },
    handleInitUsers() {
      this.userModel = {
        name: "",
        email: "",
        password: "",
        role: "",
      };

      this.editUserModal = true;
    },
    loadOrders() {
      getOrders({
        ...this.pagination,
        searchQuery: this.searchQuery,
        ...this.filters,
      }).then((res) => {
        this.orders = res.data;
        this.total = res.count;
      });
    },
    async initDeleteUser(id) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the user",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });

      if (accept && id) {
        deleteUser(id).then((res) => {
          this.runToast("bottom-right", "success", "The user was deleted");
          this.loadOrders();
        });
      }
    },
    formatDate(dateString) {
      const date = dayjs(dateString);
      return date.format("DD MMM YYYY");
    },
    createInvoice(orderId) {
      this.loading = true;
      generateInvoice(orderId)
        .then((res) => {
          if (res.success) {
            this.runToast("bottom-right", "success", "Invoice created.");
            this.loadOrders();
          } else {
            this.runToast("bottom-right", "error", "Server error!");
          }
        })
        .catch(() => {
          this.runToast("bottom-right", "error", "Somenthing went wrong!");
        })
        .finally(() => (this.loading = false));
    },
    retryTicketCreation(orderId) {
      this.loading = true;
      retryTicketCreation(orderId)
        .then((res) => {
          if (res.success) {
            this.runToast("bottom-right", "success", "Ticket created.");
            this.loadOrders();
          } else {
            this.runToast("bottom-right", "error", "Server error!");
          }
        })
        .catch(() => {
          this.runToast("bottom-right", "error", "Somenthing went wrong!");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss">
.filter-date-range {
  min-width: 250px;
  text-align: center;
}

.filters-wrapper {
  .filter-element {
    .el-input {
      input {
        min-width: 250px;
        height: 46px;
      }
    }

    margin-right: 10px;
  }
}

.el-input {
  height: 46px;
  input {
    height: 46px;
  }
  .el-input__inner {
    height: 46px;
  }
}
</style>
