<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Gardens management</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button
            v-if="getUser && getUser.role === 'admin'"
            tag="a"
            href="/#/new-garden"
            size="sm"
            type="neutral"
            >New</base-button
          >
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Gardens list</h3>
            <p class="text-sm mb-0">
              <!--              This is a client side example of paginated tables using element-ui-->
              <!--              tables.-->
            </p>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
                @change="loadUsers"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  @change="loadUsers"
                >
                </el-input>
              </div>
            </div>
            <el-table
              :data="users"
              row-key="id"
              header-row-class-name="thead-light"

            >
              <el-table-column type="selection"> </el-table-column>

              <el-table-column prop="id" label="id" width="100">
                <template #default="scope">
                  <span>{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="Name">
                <template #default="scope">
                  <span>{{ scope.row.name.it }}</span>
                  <span
                    @click="
                      showLinkModal(
                        salesURL + '/#/' + scope.row.id + '?lang=it'
                      )
                    "
                    class="garden-link"
                    ><img src="/img/brand/it.png"
                  /></span>
                  <span
                    @click="
                      showLinkModal(
                        salesURL + '/#/' + scope.row.id + '?lang=en'
                      )
                    "
                    class="garden-link"
                    ><img src="/img/brand/uk.png"
                  /></span>
                </template>
              </el-table-column>
              <el-table-column min-width="180px" align="right" label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <base-button
                      tag="a"
                      :href="'/#/garden/' + props.row.id + '/bookings'"
                      class="edit"
                      type="primary"
                      size="sm"
                      icon
                    >
                      Bookings
                    </base-button>
                    <base-button
                      tag="a"
                      :href="'/#/garden/' + props.row.id + '/tickets'"
                      class="edit"
                      type="primary"
                      size="sm"
                      icon
                      v-if="getUser && getUser.role === 'admin'"
                    >
                      Tickets
                    </base-button>
                    <base-button
                      tag="a"
                      :href="'/#/garden/' + props.row.id + '/time-slots'"
                      class="edit"
                      type="primary"
                      size="sm"
                      icon
                      v-if="getUser && getUser.role === 'admin'"
                    >
                      Time slots
                    </base-button>
                    <base-button
                      tag="a"
                      :href="'/#/garden/' + props.row.id"
                      class="edit"
                      type="primary"
                      size="sm"
                      icon
                      v-if="getUser && getUser.role === 'admin'"
                    >
                      Edit
                    </base-button>
                    <base-button
                      tag="a"
                      :href="'/#/garden/' + props.row.id + '/calendar'"
                      class="edit"
                      type="primary"
                      size="sm"
                      icon
                      v-if="getUser && getUser.role === 'admin'"
                    >
                      Calendar
                    </base-button>

                    <base-button
                      tag="a"
                      :href="'/#/garden/' + props.row.id + '/custom-ean-codes'"
                      class="edit"
                      type="primary"
                      size="sm"
                      icon
                      v-if="getUser && getUser.role === 'admin'"
                    >
                      Custom EAN codes
                    </base-button>

                    <base-button
                      @click="initDeleteGarden(props.row.id)"
                      class="remove"
                      type="danger"
                      size="sm"
                      icon
                      v-if="getUser && getUser.role === 'admin'"
                    >
                      Delete
                    </base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </template>
        </card>
        <modal v-model:show="linkModal">
          <template v-slot:header>
            <h5 class="modal-title" id="exampleModalLabel">
              Garden Public Link
            </h5>
          </template>
          <div>
            <input
              class="form-control"
              :value="linkURL"
              ref="linkURL"
              readonly
            />
          </div>
          <template v-slot:footer>
            <base-button type="info" @click="copyToClipboard"
              >Copy to clipboard</base-button
            >
            <base-button type="secondary" @click="linkModal = false"
              >Close</base-button
            >
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import BasePagination from "@/components/BasePagination";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  ElInput,
  ElOption,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";
import { deleteGarden, getGardens } from "@/api/gardens";
import swal from "sweetalert2";
import BaseButton from "@/components/BaseButton";
import modal from "@/components/Modal";
import { mapGetters } from "vuex";

export default {
  name: "Gardens",
  inject: ["runToast"],
  components: {
    BaseButton,
    BasePagination,
    RouteBreadCrumb,
    modal,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      salesURL: process.env.VUE_APP_SALES_URL,
      linkModal: false,
      linkURL: false,
      users: [],
      total: 0,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [3, 5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "name",
          label: "Name",
          minWidth: 200,
        },
      ],
    };
  },
  watch: {
    "pagination.currentPage"(val) {
      this.loadUsers();
    },
  },
  computed: {
    ...mapGetters([
      "getUser",

      // ...
    ]),
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  mounted() {
    this.loadUsers();
    console.log(process.env);
  },
  methods: {
    showLinkModal(linkURL) {
      this.linkURL = linkURL;
      this.linkModal = true;
    },
    handleSelect() {},
    loadUsers() {
      getGardens({ ...this.pagination, searchQuery: this.searchQuery }).then(
        (res) => {
          this.users = res.data;
          this.total = res.count;
        }
      );
    },
    async initDeleteGarden(id) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the garden",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });
      if (accept && id) {
        deleteGarden(id).then((res) => {
          this.runToast("bottom-right", "success", "The garden was deleted");
          this.loadUsers();
        });
      }
    },
    copyToClipboard() {
      let copyText = this.$refs["linkURL"];

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.runToast(
        "bottom-right",
        "success",
        "The link was copied to clipboard"
      );
    },
  },
};
</script>
<style lang="scss">
.garden-link {
  img {
    width: 30px;
  }
  cursor: pointer;
  margin-left: 10px;
}
</style>
