<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 v-if="garden.name" class="h2 text-white d-inline-block mb-0">
            {{ garden.name.it }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--          <base-button @click="handleInitUsers" size="sm" type="neutral"-->
          <!--            >New</base-button-->
          <!--          >-->
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="" footer-classes="pb-2">
          <template v-slot:header>
            <h3 class="mb-0">Bookings list</h3>
            <p class="text-sm mb-0">
              <!--              This is a client side example of paginated tables using element-ui-->
              <!--              tables.-->
            </p>
          </template>

          <div class="row">
            <div class="col-md-6">
              <calendar
                :min-date="new Date()"
                @dayclick="loadBookings"
                color="orange"
                is-expanded
              ></calendar>
            </div>
            <div class="col-md-6">
              <h4>Details of the day:</h4>
              {{ selectedDateLabel }}
              <h4 style="margin-top: 15px">
                Click to close one or more slots:
              </h4>

              <ul class="time-options" v-if="Object.keys(timeTicketAllDayOptions).length  >  0">
                <li
                  v-for="(timeslot, index) in timeTicketAllDayOptions"

                  :key="index"
                  @click="initStopSlots(timeslot, 'allday')"
                >
                  <div  :class="{ selected: 'allday' === selectedTime }" class="time">All Day</div>
                  <show-qty :time-slot="timeslot"></show-qty>
                </li>
              </ul>

              <ul
                class="time-options"
                v-if="Object.keys(timeTicketOptions).length  >  0"
              >
                <li
                  v-for="(timeslot, index) in timeTicketOptions"

                  :key="index"
                  @click="initStopSlots(timeslot, index)"
                >
                  <div  :class="{ selected: timeShow(index) === selectedTime }" class="time">{{ timeShow(index) }}</div>
                  <show-qty :time-slot="timeslot"></show-qty>
                </li>
              </ul>


            </div>
          </div>
        </card>
      </div>
    </div>
    <modal
      v-model:show="showTimeModal"
      modal-classes="modal-secondary"
      @close="currentSlotSelected = []"
    >
      <div>
        <h4 style="border-bottom: 1px solid #ddd;
    padding-bottom: 5px">Tickets for time slot <strong >{{selectedTime  ==='allday' ? 'All Day'  : timeShow(selectedTime)}}</strong>   of day <strong>{{selectedDate}}</strong></h4>
        <ul class="ticket-list">
          <li v-for="ticket in currentSlotSelected">
            <div>
              <strong>{{ ticket.ticketName.it }}</strong
              ><br />
              <span>
                <strong>{{ ticket.soldQty }}</strong> of
                <strong>{{ ticket.totalQty }}</strong> booked
              </span>
            </div>

            <div style="display: flex">
              <span style="margin-right: 5px">Sales status </span>
              <base-switch
                v-model="ticket.salesStatus"
                class="mr-1"
                on-text="Active"
                off-text="Stoped"
                type="success"
                @update:modelValue="updateSalesStatusForTicket(ticket, $event)"
              ></base-switch>
            </div>
          </li>
        </ul>
      </div>

      <template v-slot:footer>
        <base-button
          type="link"
          class="ml-auto"
          @click="
            showTimeModal = false;
            currentSlotSelected = [];
          "
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
import { Calendar } from "v-calendar";
import 'v-calendar/dist/style.css';

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { getGarden, getTimeOptions } from "@/api/gardens";
import { reserveTimeSlots } from "@/api/orders";
import showQty from "@/views/Pages/showQty";
import Modal from "@/components/Modal";
import {
  ElInput,
  ElOption,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";

export default {
  name: "Bookings",
  components: {
    Modal,
    showQty,
    Calendar,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  inject: ["runToast"],

  data() {
    return {
      showTimeModal: false,
      loading: false,
      garden: {},
      currentSlotSelected: [],
      selectedTime: "",
      selectedDate: "",
      selectedDateLabel: "",
      timeTicketOptions: {},
      timeTicketAllDayOptions: {},
      activeGroup: [],
    };
  },
  created() {
    this.loadGarden();
  },
  methods: {
    updateSalesStatusForTicket(data, $event) {
      if ($event === true || $event === false) {
        // console.log($event);
        reserveTimeSlots({
          group_ticket_id: data.GroupTicketId,
          date: this.selectedDate,
          salesStatus: $event,
        }).then((res) => {
          this.loadBookings({ id: this.selectedDate });
        });
      }
    },
    initStopSlots(slotTime, time) {
      this.selectedTime = time;
      this.currentSlotSelected = slotTime.map((r) => {
        // r.salesStatus = true;
        return r;
      });
      this.showTimeModal = true;
    },
    loadGarden() {
      getGarden(this.$route.params.id).then((res) => {
        this.garden = res;
      });
    },
    loadBookings({ id, ariaLabel }) {
      const params = {
        garden_id: this.$route.params.id,
        date: id,
      };

      this.selectedDate = id;
      if (ariaLabel) {
        this.selectedDateLabel = ariaLabel;
      }

      this.activeGroup = [];
      this.timeTicketOptions = {};
      this.timeTicketAllDayOptions = {};
      this.loading = true;

      getTimeOptions(params).then((res) => {
        this.activeGroup = res.activeGroup;
        let grTicket = {};
        let grTicketAllDay = {};
        for (const [key, value] of Object.entries(res.tickets)) {
          grTicket[key] = value;
        }

        for (const [key, value] of Object.entries(res.ticketsAllDay)) {
          grTicketAllDay[key] = value;
        }

        this.timeTicketOptions = this.sortByTime(grTicket);
        this.timeTicketAllDayOptions =  this.sortByTime(grTicketAllDay);

        this.loading = false;
      });
    },

    calculateTotalsQty(timeGroup) {
      const totalQty = timeGroup.reduce((acc, red) => {
        return acc + red.availableQty;
      }, 0);

      const soldQty = timeGroup.reduce((acc, red) => {
        return acc + red.soldQty;
      }, 0);

      return { totalQty, soldQty };
    },

    timeShow(timeBrut) {
      const time = timeBrut.split(":");
      return time[0] + ":" + time[1];
    },
    sortByTime(grouped) {
      return Object.keys(grouped)
        .sort((a, b) => {
          return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b);
        })
        .reduce(function (result, key) {
          result[key] = grouped[key];
          return result;
        }, {});
    },
  },
};
</script>

<style lang="scss">
.time-options {
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 8px;
    display: flex;

    .time {
      border: 1px solid;
      display: block;
      border-radius: 12px;
      padding: 5px;
      margin-right: 15px;
      width: 130px;
      text-align: center;
      font-weight: bold;

      cursor: pointer;
    }

    .time:hover {
      background-color: #dddddd !important;
    }
  }

  .selected {
    background-color: #dd8c55 !important;
  }
}

.ticket-list {
  list-style: none;
  padding-left: 0;
  li {
    margin-top: 10px;
    justify-content: space-between;
    display: flex;
  }
}

.custom-toggle {
  width: 72px !important;
}

.custom-toggle {
  input:checked + .custom-toggle-slider:before {
    transform: translateX(48px) !important;
  }
}
</style>
