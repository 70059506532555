<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 v-if="gardenModel.name" class="h2 text-white d-inline-block mb-0">
            {{ gardenModel.name.it }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <base-button
            class="btn btn-sm btn-default"
            @click="showDateRangeModal = true"
          >
            Date range
          </base-button>
          <a
            href="#"
            @click.prevent="prev"
            class="fullcalendar-btn-prev btn btn-sm btn-default"
          >
            <i class="fas fa-angle-left"></i>
          </a>
          <a
            href="#"
            @click.prevent="next"
            class="fullcalendar-btn-next btn btn-sm btn-default"
          >
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridMonth')"
          >
            Month
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridWeek')"
          >
            Week
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('timeGridDay')"
          >
            Day
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <h5 class="h3 mb-0">
                {{ currentCalendatDate ? currentCalendatDate.viewTitle : "" }}
              </h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body">
              <div id="fullCalendar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-model:show="showAddModal" modal-classes="modal-secondary">
      <form class="new-event--form" @submit.prevent="saveEvent">
        <base-input
          name="title"
          label="Event title"
          placeholder="Event Title"
          v-model="model.title"
          input-classes="form-control-alternative new-event--title"
        >
        </base-input>
        <div class="form-group">
          <label class="form-control-label d-block mb-3">Status color</label>
          <div class="btn-group btn-group-toggle btn-group-colors event-tag">
            <label
              v-for="color in eventColors"
              :key="color"
              class="btn"
              :class="[color, { 'active focused': model.className === color }]"
            >
              <input
                v-model="model.className"
                type="radio"
                name="event-tag"
                :value="color"
                autocomplete="off"
              />
            </label>
          </div>
        </div>
        <input type="hidden" class="new-event--start" />
        <input type="hidden" class="new-event--end" />
      </form>

      <template v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add"
          @click="saveEvent"
        >
          Add event
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showAddModal = false"
        >
          Close
        </button>
      </template>
    </modal>

    <modal v-model:show="showEditModal" modal-classes="modal-secondary">
      <form class="edit-event--form" @submit.prevent="editEvent">
        <base-input
          name="title2"
          label="Event title"
          placeholder="Event Title"
          v-model="model.title"
          input-classes="form-control-alternative new-event--title"
        >
        </base-input>
        <div class="form-group">
          <label class="form-control-label d-block mb-3">Status color</label>
          <div class="btn-group btn-group-toggle btn-group-colors event-tag">
            <label
              v-for="color in eventColors"
              :key="color"
              class="btn"
              :class="[color, { 'active focused': model.className === color }]"
            >
              <input
                v-model="model.className"
                type="radio"
                name="event-tag"
                :value="color"
                autocomplete="off"
              />
            </label>
          </div>
        </div>
        <base-input name="textarea" label="Description">
          <textarea
            v-model="model.description"
            class="form-control form-control-alternative edit-event--description textarea-autosize"
            placeholder="Event Desctiption"
          >
          </textarea>
          <i class="form-group--bar"></i>
        </base-input>
        <input type="hidden" class="new-event--start" />
        <input type="hidden" class="new-event--end" />
      </form>

      <template v-slot:footer>
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="editEvent"
          >Update</base-button
        >
        <base-button type="danger" @click="deleteEvent">Delete</base-button>
        <base-button type="link" class="ml-auto" @click="showAddModal = false"
          >Close</base-button
        >
      </template>
    </modal>

    <modal v-model:show="showDateRangeModal">
      <template v-slot:header>
        <h5 class="modal-title">
          Date range config
        </h5>
      </template>
      <div class="group-type">
        <label class="col-2">Group</label>
        <select class="form-control ml-2 d-inline-block col-8" v-model="selectedDateRangeGroup">
          <option :value="null" disabled>Select a group...</option>
          <option v-for="group in availableGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
        </select>
      </div>
      <div class="group-type mt-4">
        <label class="col-2">Range</label>
        <el-date-picker
          class="ml-2"
          v-model="selectedDateRangeDate"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          size="large"
          format="DD-MM-YYYY"
          :disabled="!selectedDateRangeGroup"
        />
      </div>
      <div class="group-type d-flex mt-4">
        <label class="col-2">Day</label>
        <div class="ml-2 d-inline-block col-8">
          <el-radio-group v-model="selectedDateRangeDayType" size="large" :disabled="!selectedDateRangeGroup" @change="onDateRangeDayTypeChange($event)">
            <el-radio-button label="All days" />
            <el-radio-button label="Selected week days" />
          </el-radio-group>

          <el-checkbox-group :disabled="!selectedDateRangeGroup || (selectedDateRangeDayType != 'Selected week days')" v-model="selectedDateRangeWeekDays">
            <el-checkbox label="Sunday" />
            <el-checkbox label="Monday" />
            <el-checkbox label="Tuesday" />
            <el-checkbox label="Wednesday" />
            <el-checkbox label="Thursday" />
            <el-checkbox label="Friday" />
            <el-checkbox label="Saturday" />
          </el-checkbox-group>
        </div>
      </div>
      <template v-slot:footer>
        <div class="action-buttons">
          <button class="btn btn-sm btn-default" @click="activateGroup()">Activate group</button>
          <button class="btn btn-sm btn-warning" @click="deactivateGroup()">Deactivate group</button>
          <button @click="showDateRangeModal = false;resetDateRangeModal()" class="btn btn-sm btn-danger">
            Cancel
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridDay from "@fullcalendar/timegrid";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  deactivateCalendarGroup,
  getGarden,
  getGardenCalendar,
  setActiveCalendarGroup,
} from "@/api/gardens";
import { ElDatePicker, ElCheckboxGroup, ElCheckbox, ElRadioGroup, ElRadioButton } from "element-plus";
import { ref } from 'vue';

const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();
const d = today.getDate();

export default {
  name: "calendar",
  components: {
    RouteBreadCrumb,
    Modal,
    [ElDatePicker.name]: ElDatePicker,
    [ElCheckboxGroup.name]: ElCheckboxGroup,
    [ElCheckbox.name]: ElCheckbox,
    [ElRadioGroup.name]: ElRadioGroup,
    [ElRadioButton.name]: ElRadioButton,
  },
  data() {
    return {
      calendar: null,
      gardenModel: {},

      model: {
        title: "New event",
        className: "bg-default",
        description:
          "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        start: "",
        end: "",
      },
      showAddModal: false,
      showEditModal: false,
      showDateRangeModal: false,
      availableGroups: [],
      selectedDateRangeGroup: null,
      selectedDateRangeDate: null,
      selectedDateRangeDayType: ref('All days'),
      selectedDateRangeWeekDays: [],
      currentCalendatDate: false,
      eventColors: [
        "bg-info",
        "bg-orange",
        "bg-red",
        "bg-green",
        "bg-default",
        "bg-blue",
        "bg-purple",
        "bg-yellow",
      ],
    };
  },
  methods: {
    initCalendar(initialDate) {
      var calendarEl = document.getElementById("fullCalendar");

      this.calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridDay, interactionPlugin],
        timeZone: "UTC",
        selectable: true,
        headerToolbar: false,
        initialDate,
        fixedWeekCount: false,
        select: () => {
          this.showAddModal = true;
          this.model.start = new Date(y, m, 21);
          this.model.end = new Date(y, m, 21);
        },
        datesSet: () => {
          this.currentCalendatDate = this.calendar.currentData;
        },
        eventContent: (arg) => {
          let domNodes = [];
          let button = document.createElement("button");
          button.innerText = "Deactivate all";
          button.classList.add("remove-day-status");
          button.classList.add("btn");
          button.classList.add("btn-dribbble");
          button.classList.add("btn-sm");

          button.setAttribute("day-id", arg.event.start.toJSON().slice(0, 10));

          arg.event.extendedProps.groups.forEach((res) => {
            let checkDiv = document.createElement("div");

            let input = document.createElement("input");
            input.classList.add("change-day-status");
            input.setAttribute("type", "checkbox");
            input.setAttribute(
              "name",
              arg.event.start.toJSON().slice(0, 10) + "-groups"
            );
            if (res.active) {
              input.setAttribute("checked", true);
            }

            input.setAttribute("value", res.id);
            input.setAttribute("day-id", arg.event.start.toJSON().slice(0, 10));

            let label = document.createElement("label");
            label.innerText = res.name;
            checkDiv.appendChild(input);
            checkDiv.appendChild(label);


            domNodes.push(checkDiv);
          });
          domNodes.push(button)
          return {
            domNodes: domNodes,
          };
        },

        eventClick: () => {
          // this.model = {
          //   title: event.title,
          //   className: event.classNames ? event.classNames.join(" ") : "",
          //   start: event.start,
          //   end: event.end,
          //   description:
          //     "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
          // };
          // this.showEditModal = true;
        },
        events: (info, successCallback, failureCallback) => {
          getGardenCalendar(this.$route.params.id, {
            start: info.start.valueOf(),
            end: info.end.valueOf(),
          })
            .then((res) => {
              successCallback(res);
            })
            .catch((res) => failureCallback(res.error));
        },
      });
      this.calendar.render();

      this.currentCalendatDate = this.calendar.currentData;
    },
    changeView(newView) {
      this.calendar.changeView(newView);
      this.calendar.view.title;
    },
    next() {
      this.calendar.next();
    },
    prev() {
      this.calendar.prev();
    },
    saveEvent() {
      if (this.model.title) {
        let event = {
          ...this.model,
          allDay: true,
        };
        this.events.push(JSON.parse(JSON.stringify(event)));
        console.log(this.events);
        this.model = {
          title: "",
          eventColor: "bg-danger",
          start: "",
          end: "",
        };
      }
      this.showAddModal = false;
    },
    editEvent() {
      // let index = this.events.findIndex((e) => e.title === this.model.title);
      // if (index !== -1) {
      //   this.events.splice(index, 1, this.model);
      // }
      // this.showEditModal = false;
    },
    deleteEvent() {
      let index = this.events.findIndex((e) => e.title === this.model.title);
      if (index !== -1) {
        this.events.splice(index, 1);
      }
      this.showEditModal = false;
    },
    async getGardenObject() {
      // update `props.user` to `user.value` to access the Reference value
      let garden = await getGarden(this.$route.params.id);
      this.availableGroups = [ ...garden.groups ];
      this.gardenModel = await getGarden(this.$route.params.id, true);
    },
    async activateGroup() {
      if (!this.selectedDateRangeGroup || !this.selectedDateRangeDate || !this.selectedDateRangeDayType)
        return;

      let currentDate = new Date(this.selectedDateRangeDate[0]);
      let maxDate = new Date(this.selectedDateRangeDate[1]);
      while (currentDate <= maxDate) {
        if (this.selectedDateRangeDayType === 'All days' || this.selectedDateRangeWeekDays.indexOf(currentDate.toLocaleDateString('en-EN', { weekday: 'long' })) > -1) {
          await setActiveCalendarGroup(this.$route.params.id, {
            group_id: this.selectedDateRangeGroup.toString(),
            date: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`,
            active: true,
          });
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.showDateRangeModal = false;
      // Initial date for calendar, must be date + 1, because of a bug in setting initial date.
      let initialDate = new Date(this.selectedDateRangeDate[0]);
      initialDate.setDate(initialDate.getDate() + 1);
      this.resetDateRangeModal();
      this.initCalendar(initialDate);
    },
    async deactivateGroup() {
      if (!this.selectedDateRangeGroup || !this.selectedDateRangeDate || !this.selectedDateRangeDayType)
        return;

      let currentDate = new Date(this.selectedDateRangeDate[0]);
      let maxDate = new Date(this.selectedDateRangeDate[1]);
      while (currentDate <= maxDate) {
        if (this.selectedDateRangeDayType === 'All days' || this.selectedDateRangeWeekDays.indexOf(currentDate.toLocaleDateString('en-EN', { weekday: 'long' })) > -1) {
          await setActiveCalendarGroup(this.$route.params.id, {
            group_id: this.selectedDateRangeGroup.toString(),
            date: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`,
            active: false,
          });
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.showDateRangeModal = false;
      // Initial date for calendar, must be date + 1, because of a bug in setting initial date.
      let initialDate = new Date(this.selectedDateRangeDate[0]);
      initialDate.setDate(initialDate.getDate() + 1);
      this.resetDateRangeModal();
      this.initCalendar(initialDate);
    },
    onDateRangeDayTypeChange(value) {
      if (value === 'All days') {
        this.selectedDateRangeWeekDays = [];
      }
    },
    resetDateRangeModal() {
      this.selectedDateRangeGroup = null;
      this.selectedDateRangeDate = null;
      this.selectedDateRangeDayType = ref('All days');
      this.selectedDateRangeWeekDays = [];
    },
  },
  mounted() {
    this.initCalendar();
    this.getGardenObject();
    document.addEventListener("click", (e) => {
      if (e.target && e.target.classList.contains("remove-day-status")) {
        const dayID = e.target.getAttribute("day-id");
        deactivateCalendarGroup(this.$route.params.id, {
          date: dayID,
        }).then((res) => {

          this.calendar.refetchEvents();

        });

      }

      if (e.target && e.target.classList.contains("change-day-status")) {

        const value = e.target.value;
        const checked = e.target.checked;
        console.log(checked);
        console.log(value);

        if (value) {
          const dayID =  e.target.getAttribute("day-id");
          console.log(dayID);

          setActiveCalendarGroup(this.$route.params.id, {
            group_id: value,
            date: dayID,
            active: checked,
          }).then((res) => {});
        }
      }
    });
  },
};
</script>
<style lang="scss">
@import "~@/assets/sass/core/vendors/fullcalendar";
.fc-event-main {
  padding: 5px;
  padding-left: 10px;
  padding-top: 10px;
  label {
    margin-bottom: 5px;
    margin-left: 5px;
  }
}

.remove-day-status {

margin-bottom: 5px;
}

.fc-event-main {
  display: flex;
  flex-direction: column;
}

</style>
