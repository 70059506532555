import { createRouter, createWebHashHistory } from "vue-router";

import store from "@/store";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";

import Login from "../views/Pages/Login.vue";
import Calendar from "../views/Calendar/Calendar";
import Users from "../views/Pages/Users.vue";
import Gardens from "@/views/Pages/Gardens";
import NewGarden from "@/views/Pages/NewGarden";
import EditGarden from "@/views/Pages/EditGarden";
import GardenTicketManagement from "@/views/Pages/GardenTicketManagement";
import GardenTimeSoltsManagement from "@/views/Pages/GardenTimeSoltsManagement";
import Orders from "@/views/Pages/Orders";
import Order from "@/views/Pages/Order";
import Bookings from "@/views/Pages/Bookings";
import EanCodesGroup from "../views/Pages/EanCodesGroup";

const routes = [
  {
    path: "/",
    // redirect: "",
    component: DashboardLayout,
    name: "Homepage",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: { roles: ["admin"] },
      },
    ],
  },
  {
    path: "/",
    redirect: "/users",
    component: DashboardLayout,
    name: "Users",
    children: [
      {
        path: "/users",
        name: "UsersManagement",
        components: { default: Users },
        meta: { roles: ["admin"] },
      },
    ],
  },
  {
    path: "/",
    redirect: "/orders",
    component: DashboardLayout,
    name: "Orders",
    children: [
      {
        path: "/orders",
        name: "OrdersManagement",
        components: { default: Orders },
        meta: { roles: ["admin", "garden_manager"] },
      },
      {
        path: "/orders/:id",
        name: "Order",
        components: { default: Order },
        meta: { roles: ["admin", "garden_manager"] },
      },
    ],
  },
  {
    path: "/",
    redirect: "/gardens",
    component: DashboardLayout,
    name: "Gardens",
    children: [
      {
        path: "/gardens",
        name: "Gardens List",
        components: { default: Gardens },
        meta: { roles: ["admin", "garden_manager"] },
      },
      {
        path: "/new-garden",
        name: "Add New Garden",
        components: { default: NewGarden },
        meta: { roles: ["admin"] },
      },
      {
        path: "/garden/:id",
        name: "Edit garden",
        components: { default: EditGarden },
        meta: { roles: ["admin"] },
      },
      {
        path: "/garden/:id/tickets",
        name: "Add tickets",
        components: { default: GardenTicketManagement },
        meta: { roles: ["admin"] },
      },
      {
        path: "/garden/:id/time-slots",
        name: "Time slots",
        components: { default: GardenTimeSoltsManagement },
        meta: { roles: ["admin"] },
      },
      {
        path: "/garden/:id/bookings",
        name: "Bookings",
        components: { default: Bookings },
        meta: { roles: ["admin", "garden_manager"] },
      },

      {
        path: "/garden/:id/custom-ean-codes",
        name: "EanCodesGroup",
        components: { default: EanCodesGroup },
        meta: { roles: ["admin", "garden_manager"] },
      },

      {
        path: "/garden/:id/calendar",
        name: "Calendar",
        components: { default: Calendar },
        meta: { roles: ["admin", "garden_manager"] },
      },
    ],
  },
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      // {
      //   path: "/register",
      //   name: "Register",
      //   components: { default: Register },
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.getUser && store.getters.getUser.id;

  if (to.name !== "Login" && !isAuthenticated) {
    next({ name: "Login" });
  } else {
    if (to.meta.roles) {
      if (!to.meta.roles.includes(store.getters.getUser.role)) {
        next({ name: "Dashboard" });
      }
    }

    next();
  }
});

export default router;
