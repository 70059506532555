<template>
  <div class="row" v-loading="true" id="loading"></div>
</template>

<style>
#loading {
  position: fixed !important;
  height: 100dvh;
  height: 100vh;
  width: 100dvw;
  width: 100vw;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
}

.el-loading-spinner .path {
  stroke: #66615b !important;
}

.el-loading-mask {
  position: fixed !important;
  height: 100dvh;
  height: 100vh;
  width: 100dvw;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2) !important;
}
</style>
<script>
export default {
  name: "loading-panel"
}
</script>
