import request from "@/util/request";

export function getOrders(params) {
  return request({
    url: "/orders",
    method: "get",
    params: params,
  });
}

export function getDashboardData(params) {
  return request({
    url: "/orders/dashboard",
    method: "get",
    params: params,
  });
}

export function getOrder(id, params) {
  return request({
    url: "/orders/" + id,
    method: "get",
    params: params,
  });
}

export function reserveTimeSlots(data) {
  return request({
    url: "/orders/bookings/reserve-time-slots",
    method: "post",
    data: data,
  });
}

export function generateInvoice(orderId) {
  return request({
    url: "/orders/generate-invoice",
    method: "post",
    data: { id: orderId },
  });
}

export function retryTicketCreation(orderId) {
  return request({
    url: "/orders/recreate-ticket",
    method: "post",
    data: { id: orderId },
  });
}

// export function createUser(data) {
//   return request({
//     url: "/users",
//     method: "post",
//     data: data,
//   });
// }
//
// export function updateUser(id, data) {
//   return request({
//     url: "/users/" + id,
//     method: "patch",
//     data: data,
//   });
// }
//
// export function deleteUser(id) {
//   return request({
//     url: "/users/" + id,
//     method: "delete",
//   });
// }
//
// export function getGardensAdmins(params) {
//   return request({
//     url: "/users/gardens-admins",
//     method: "get",
//     data: {
//       params: params,
//     },
//   });
// }
