<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-shop text-primary',
            path: '/dashboard',
          }"
          v-if="getUser && getUser.role === 'admin'"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Users',
            icon: 'ni ni-single-02 text-primary',
            path: '/users',
          }"
          v-if="getUser && getUser.role === 'admin'"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Gardens',
            icon: 'ni ni-square-pin text-primary',
            path: '/gardens',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Orders',
            icon: 'ni ni-cart text-primary',
            path: '/orders',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { mapGetters } from "vuex";

export default {
  name: "DashboardLayout",
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  computed: {
    ...mapGetters([
      "getUser",

      // ...
    ]),
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    // this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
