import request from "@/util/request";

export function getGardens(params) {
  return request({
    url: "/gardens",
    method: "get",
    params: params,
  });
}

export function getGardenEanCodesGroups(params) {
  return request({
    url: "/gardens/ean-code-groups/get",
    method: "get",
    params: params,
  });
}

export function addGardenEanCodesGroups(payload) {
  return request({
    url: "/gardens/ean-code-groups",
    method: "post",
    data: payload,
  });
}

export function updateGardenEanCodesGroups(data) {
  return request({
    url: "/gardens/ean-code-groups/update",
    method: "patch",
    data: data,
  });
}

export function deleteGardenEanCodesGroups(id) {
  return request({
    url: "/gardens/ean-code-groups/delete/" + id,
    method: "delete",
  });
}

export function createGarden(payload) {
  return request({
    url: "/gardens",
    method: "post",
    data: payload,
  });
}

export function getGarden(
  id,
  loadRelationIds = false,
  activeTicketsOnly = true
) {
  return request({
    url: "/gardens/" + id,
    method: "get",
    params: {
      loadRelationIds: loadRelationIds,
      activeTicketsOnly: activeTicketsOnly,
    },
  });
}

export function updateGarden(id, data) {
  return request({
    url: "/gardens/" + id,
    method: "patch",
    data: data,
  });
}

export function createTicketGarden(id, data) {
  return request({
    url: "/gardens/tickets",
    method: "post",
    data: data,
  });
}

export function updateTicketGarden(id, data) {
  return request({
    url: "/gardens/tickets/" + id,
    method: "patch",
    data: data,
  });
}

export function updateOrCreateGroup(id, data) {
  return request({
    url: "/gardens/" + id + "/groups",
    method: "post",
    data: data,
  });
}

export function removeTicketsFromGroups(data) {
  return request({
    url: "/gardens/groups/tickets",
    method: "delete",
    data: data,
  });
}

export function deleteGroup(id) {
  return request({
    url: "/gardens/groups/" + id,
    method: "delete",
  });
}

export function deleteTicket(id) {
  return request({
    url: "/gardens/tickets/" + id,
    method: "delete",
  });
}

export function deleteGarden(id) {
  return request({
    url: "/gardens/" + id,
    method: "delete",
  });
}

export function getGardenCalendar(id, params) {
  return request({
    url: "/gardens/" + id + "/calendar",
    method: "get",
    params: params,
  });
}

export function setActiveCalendarGroup(id, data) {
  return request({
    url: "/gardens/" + id + "/calendar",
    method: "post",
    data: data,
  });
}

export function getTimeOptions(params) {
  return request({
    url: "/orders/check-free-tickets/get-time",
    method: "get",
    params: params,
  });
}

export function deactivateCalendarGroup(id, data) {
  return request({
    url: "/gardens/" + id + "/calendar-deactivate",
    method: "post",
    data: data,
  });
}
