<template>
  <div>
  <router-view></router-view>
  </div>
</template>

<script>
import { provide, ref } from "vue";

import { useToast } from "vue-toastification";
import { getUser } from "@/api/auth";
import { useStore } from "vuex";
export default {
  setup() {
    const user = ref({});
    const store = useStore();
    const runToast = (pos, type, ownText, ownIcon) => {
      const icon = "ni ni-bell-55";

      const toast = useToast();
      toast(ownText, {
        type: type,
        hideProgressBar: true,
        icon: icon,
        closeButton: false,
        position: pos,
      });
    };
    const loadUser = async () => {
      try {
        const res = await getUser();
        user.value = res.user;
        store.commit("setUser", res.user);
        return user.value;
      } catch (e) {}
    };
    loadUser();
    provide("runToast", runToast);
    provide("loadUser", loadUser);
    provide("user", user);
    return {
      user,
    };
  },
};
</script>
